(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/session/assets/javascripts/user-session.js') >= 0) return;  svs.modules.push('/components/core/session/assets/javascripts/user-session.js');

var SessionStore = svs.core.session.SessionStore;

var SessionRoleEnum = {
  PLAYER: 'Player',
  INTERNAL: 'Internal',
  RETAILER: 'Retailer', 
  RETAILER_CORP: 'RetailerCorp', 
  RETAILER_LOGIN: 'RetailerLogin',
  ADMIN_RETAILER: 'AdminRetailer',
  EXTERNAL_PARTNER: 'ExternalPartner', 
  CUSTOMERSERVICE: 'CustomerService',
  RETAILERSALES: 'RetailerSales'
};

var SessionLogoutReasonEnum = {
  LOGOUT: 'LOGOUT',
  INVALID_SESSION: 'INVALID_SESSION'
};

var anonymousUserSession = {
  isAnonymous: true,
  hasRole: function() {
    return false;
  },
  roles: SessionRoleEnum
};

function UserSession(options) {
  SessionStore.call(this, options);

  this.__userSession = svs.core.session.data.userSession ?
    svs.core.session.data.userSession :
    anonymousUserSession;

  this.roles = SessionRoleEnum;
  this.logoutReason = SessionLogoutReasonEnum;

  this.logout = function(reason) {
    var self = this;

    reason = reason || SessionLogoutReasonEnum.LOGOUT;

    this.deleteSession(function() {
      self.afterLogout(self.__userSession.role, reason, function() {
        if (self.hasRole(SessionRoleEnum.INTERNAL)) {
          var internalLoginPageUrl = svs.core.urlMapping.get('internalLoginHome');
          var startPageUrl = svs.core.urlMapping.get('startPage');

          window.location.href = reason === SessionLogoutReasonEnum.LOGOUT ?
            internalLoginPageUrl || '' :
            startPageUrl || '/';
        } else if (self.hasRole(SessionRoleEnum.RETAILER)) {
          svs.core.deviceSession.remove('lastLoginTime', null, function() {
            svs.core.deviceSession.remove('userNotifications', null, function() {
              svs.core.deviceSession.remove('sequencenumber', null, function() {
                window.location.reload();
              });
            });
          });
        } else if (self.hasRole(SessionRoleEnum.RETAILER_CORP)) {
          window.location.reload();
        } else if (self.hasRole(SessionRoleEnum.EXTERNAL_PARTNER)) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      });
    });
  };

  this.deleteSession = function(callback) {
    var options = {
      method: 'DELETE',
      useExtErrFunc: false 
    };

    if (this.hasRole(SessionRoleEnum.PLAYER)) {
      options.path = '/player/1/sessions';
    } else if (this.hasRole(SessionRoleEnum.INTERNAL)) {
      options.path = '/user/1/internalSessions';
    } else if (this.hasRole(SessionRoleEnum.RETAILER)) {
      options.path = '/retailer/1/sessions';
    } else if (this.hasRole(SessionRoleEnum.RETAILER_CORP)) {
      options.path = '/retailer/1/sessions';
    } else if (this.hasRole(SessionRoleEnum.EXTERNAL_PARTNER)) {
      options.path = '/externalpartner/1/sessions';
    } else {
      options.path = '/player/1/sessions';
    }

    svs.core.jupiter.call(options, callback, callback);
  };

  this.afterLogout = function(role, reason, defaultCallback) {
    var preventDefault = false;

    this.trigger('afterLogout', {
      role: role,
      reason: reason,
      preventDefault: function() {
        preventDefault = true;
      }
    });

    if (!preventDefault) {
      defaultCallback();
    }
  };

  this.hasRole = function(role) {
    var currentRoles = this.__userSession.role ? [this.__userSession.role.toLowerCase()] : [];

    var currentSubRoles = [];
    if (Array.isArray(this.__userSession.subroles)) {
      currentSubRoles = this.__userSession.subroles.map(function(subrole) {
        return subrole.toLowerCase();
      });
    }
    var totalRoles = currentRoles.concat(currentSubRoles);

    if (role === 'RetailerSales') {
      return currentRoles.includes('retailer') && currentSubRoles.includes('retailer');
    }

    if (!role) {
      return false;
    }

    if (Array.isArray(role)) {
      var matchingRoles = role.filter(function(r) {
        return totalRoles.indexOf(r.toLowerCase()) !== -1;
      });

      return Boolean(matchingRoles.length);
    }

    return totalRoles.indexOf(role.toLowerCase()) !== -1;
  };

  this.isAnonymous = function() {
    return this.__userSession.isAnonymous;
  };

  this.isBlocked = function() {
    return this.__userSession.isBlocked;
  };

  this.getUser = function() {
    return {
      firstName: this.__userSession.firstName,
      lastName: this.__userSession.lastName
    };
  };

  this.getLoginType = function() {
    return this.__userSession.loginType;
  };

  this.getSegmentId = function() {
    return this.__userSession.segment ? this.__userSession.segment : 0;
  };
}

UserSession.prototype = Object.create(SessionStore.prototype);

var userSession = new UserSession({
  url: '/player/1/sessionstoreitems',
  key: 'trinidad_user',
  maxSizeKB: 1,
  data: svs.core.session.data.userSession && svs.core.session.data.userSession.data
});

svs.core.session.UserSession = UserSession;
svs.core.userSession = new svs.core.Events(userSession);


 })(window);