(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/session/assets/javascripts/helpers/handlebarhelper.js') >= 0) return;  svs.modules.push('/components/core/session/assets/javascripts/helpers/handlebarhelper.js');

(function() {
  'use strict';

  var isServerSide = typeof exports === 'object';

  function registerHelpers(hbs) {
    hbs.registerHelper('passwordLogin', function(options) {
      var passwordLogin;
      if (isServerSide) {
        passwordLogin = options.data.root.session.loginType ?
          options.data.root.session.loginType === 'PASSWORD' :
          true;
      } else {
        passwordLogin = svs.core.userSession ?
          svs.core.userSession.getLoginType() === 'PASSWORD' :
          true;
      }

      if (options.fn) {
        if (passwordLogin) {
          return options.fn(this);
        }
        return options.inverse(this);
      }
      return passwordLogin;
    });

    hbs.registerHelper('isAnonymous', function(options) {
      var isAnonymous = isServerSide ?
        options.data.root.session && options.data.root.session.isAnonymous :
        svs.core.userSession.isAnonymous();

      if (options.fn) {
        if (isAnonymous) {
          return options.fn(this);
        }
        return options.inverse(this);
      }
      return isAnonymous;
    });

    hbs.registerHelper('hasRole', function(session, options) {
      if (!options) {
        options = session;
        session = null;
      }

      session = isServerSide ? options.data.root.session : svs.core.userSession;

      if (options.fn) {
        if (session.hasRole(options.hash.role)) {
          return options.fn(this);
        }
        return options.inverse(this);
      }
      return session.hasRole(options.hash.role);
    });

    hbs.registerHelper('firstName', function(options) {
      var user = isServerSide ?
        options.data.root.session :
        svs.core.userSession.getUser();
      return user.firstName;
    });
  }

  if (svs.isServer) {
    registerHelpers(require('hbs'));
  } else {
    registerHelpers(Handlebars);
  }
})();


 })(window);