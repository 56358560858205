(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/session/assets/javascripts/device-session.js') >= 0) return;  svs.modules.push('/components/core/session/assets/javascripts/device-session.js');

var SessionStore = svs.core.session.SessionStore;

function getCompleteKey(key, domain) {
  return domain ? domain + '.' + key : key;
}

var callbackQueue = [];
var keyDeletionQueue = [];
var domainDeletionQueue = [];

function DeviceSession(options) {
  SessionStore.call(this, options);

  this.get = function(key, domain, callback, context) {
    SessionStore.prototype.get.call(this, getCompleteKey(key, domain), function(err, value) {
      callback && callback.call(context, value, err);
    });
  };

  this.set = function(key, domain, value, callback, context) {
    SessionStore.prototype.set.call(this, getCompleteKey(key, domain), value, function(err, newValue) {
      callback && callback.call(context, err, newValue);
    });
  };

  this.remove = function(keyName, domain, callback, context) {
    var self = this;

    var callCallbacks = function(q, err) {
      q.forEach(function(cb) {
        cb.callback && cb.callback.call(cb.context, err);
      });
      q.length = 0;
    };

    var getRegExpKey = function(key, domain) {
      if (!domain) {
        return new RegExp('^' + key + '$');
      } else if (!key) {
        return new RegExp('^' + domain + '\\.');
      }
      return new RegExp(domain + '\\.' + key);
    };

    var queueEmpty = !domainDeletionQueue.length && !keyDeletionQueue.length;

    if (!keyName && domain) {
      if (!domainDeletionQueue.length || domainDeletionQueue.indexOf(domain) !== -1) {
        domainDeletionQueue.push(domain);
        if (keyDeletionQueue.length) {
          keyDeletionQueue = keyDeletionQueue.filter(function(item) {
            return item.domain !== domain;
          });
        }
      }
    } else if (keyName) {
      if (!keyDeletionQueue.length ||
        keyDeletionQueue.map(function(item) {
          return item.key;
        }).indexOf(keyName) !== -1 && !domainDeletionQueue.length || !domain || domainDeletionQueue.indexOf(domain) !== -1) {
        keyDeletionQueue.push({ key: keyName, domain: domain });
      }
    }

    if (keyName || domain) {
      callbackQueue.push({ callback: callback, context: context });
    } else {
      callback.call(context);
    }

    if (queueEmpty) {
      this.getSessionData(function(err, sessionModel) {
        var domainQC = domainDeletionQueue.slice();
        var keyQC = keyDeletionQueue.slice();
        var cbQC = callbackQueue.slice();

        keyDeletionQueue.length = domainDeletionQueue.length = callbackQueue.length = 0;

        if (err) {
          callCallbacks(cbQC, err);
        } else {
          var removeList = [];

          removeList = removeList.concat(domainQC.map(function(domain) {
            return getRegExpKey(undefined, domain);
          }));

          removeList = removeList.concat(keyQC.map(function(options) {
            return getRegExpKey(options.key, options.domain);
          }));

          var mutated = false;

          Object.keys(sessionModel).forEach(function(key) {
            if (removeList.filter(function(regTest) {
              return key.match(regTest);
            }).length) {
              delete sessionModel[key];
              mutated = true;
            }
          });
          if (mutated) {
            self.persistSessionData(sessionModel, function(error) {
              callCallbacks(cbQC, error);
            });
          } else {
            callCallbacks(cbQC);
          }
        }
      });
    }
  };
}

DeviceSession.prototype = Object.create(SessionStore.prototype);

svs.core.deviceSession = new DeviceSession({
  url: '/user/1/sessionstoreitems',
  key: 'trinidad_session',
  maxSizeKB: 100,
  data: svs.core.session.data.session
});


 })(window);