(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/session/assets/javascripts/session-store.js') >= 0) return;  svs.modules.push('/components/core/session/assets/javascripts/session-store.js');

var logger = svs.core.log.getLogger('core:session:session-store');

function doneAsync(callback, data) {
  setTimeout(function() {
    return callback && callback(null, data);
  }, 1);
}

function SessionStore(options) {
  this.url = options.url;
  this.key = options.key;
  this.maxSize = (options.maxSizeKB || 0.1) * 1000;
  this.__sessionData = options.data;
}

SessionStore.prototype.getSessionData = function(callback) {
  var self = this;

  if (this.__sessionData) {
    doneAsync(callback, JSON.parse(JSON.stringify(this.__sessionData)));
  } else {
    svs.core.jupiter.get(self.url + '/' + self.key, function(result) {
      self.__sessionData = result.data ? JSON.parse(result.data) : {};

      callback && callback(null, self.__sessionData);
    }, function(err) {
      callback && callback(err);
    });
  }
};

SessionStore.prototype.get = function(key, callback) {
  this.getSessionData(function(err, sessionData) {
    if (err) {
      callback && callback(err);
    } else {
      callback && callback(null, sessionData[key]);
    }
  });
};

SessionStore.prototype.persistSessionData = function(sessionData, callback) {
  var self = this;

  if (sessionData.__hash) {
    delete sessionData.__hash;
  }

  var sessionDataStr = JSON.stringify(sessionData);

  var options = {
    method: 'POST',
    path: this.url,
    data: {
      key: this.key,
      data: sessionDataStr
    }
  };

  svs.core.jupiter.call(options, function() {
    self.__sessionData = JSON.parse(sessionDataStr);

    callback(null, self.__sessionData);
  }, function(err) {
    callback(err);
  });
};

SessionStore.prototype.set = function(key, value, callback) {
  var self = this;

  this.getSessionData(function(err, sessionData) {
    var oldValueStr = '';
    if (typeof sessionData === 'object' && !Array.isArray(sessionData) && sessionData !== null) {
      try {
        oldValueStr = JSON.stringify(sessionData[key]);
      } catch (err) {
        logger.warn('Failed to get session data.' + err.message);
      }
    }
    var newValueStr = JSON.stringify(value);

    if (newValueStr === oldValueStr) {
      callback && callback(null, value);
    } else if (err) {
      callback && callback(err);
    } else {
      if (newValueStr.length > self.maxSize) {
        logger.warn('Object saved to storage exceeds recommended size! Key=' + key);
      }

      sessionData[key] = value;

      self.persistSessionData(sessionData, function(err, newSessionData) {
        if (err) {
          callback && callback(err);
        } else {
          self.trigger && self.trigger('update', { key: key, value: newSessionData[key] });

          callback && callback(null, newSessionData[key]);
        }
      });
    }
  });
};

SessionStore.prototype.remove = function(key, callback) {
  var self = this;

  this.getSessionData(function(err, sessionData) {
    if (!sessionData[key]) {
      callback && callback();
    } else if (err) {
      callback && callback(err);
    } else {
      delete sessionData[key];

      self.persistSessionData(sessionData, function(err) {
        if (err) {
          callback && callback(err);
        } else {
          callback && callback();
        }
      });
    }
  });
};

svs.core.session.SessionStore = SessionStore;


 })(window);